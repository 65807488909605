<template>
	<div>
		<b-card>
			<div class="list-view" v-on:click="setterView()" style="cursor: pointer">
				<b-row>
					<b-col md="6">
						<div class="d-flex justify-content-between">
							<div>
								<span class="head-text">
									{{ setterName }}
									<span class="success-text"> ({{ deviceId }})</span>
								</span>
							</div>
							<div v-if="deviceId === 'No Device'"></div>
							<div v-else class="d-flex">
								<div class="mr-5">
									<span class="mr-1">
										<b-img
											:src="
												require('@/assets/images/svg/machine/temprature.svg')
											"
										/>
									</span>
									<span class="head-text"
										>{{ temperature }}
										<sup
											style="color: #f6667e; font-size: 10px; margin-right: 3px"
											>0</sup
										><span class="red-text">c</span></span
									>
								</div>
								<div>
									<span class="mr-1">
										<b-img
											:src="require('@/assets/images/svg/machine/humidity.svg')"
										/>
									</span>
									<span class="info-text"
										>{{ humidity }}

										<span style="font-size: 12px">%</span></span
									>
								</div>
							</div>
						</div>
					</b-col>
					<b-col md="6 d-flex justify-content-end">
						<!-- <div class="edit-icon">
              <feather-icon icon="EditIcon" size="15" class="mr-2" />
            </div> -->
						<div class="mr-2">
							<div v-if="status === 'Active'" class="activeClass">Active</div>
							<div v-else class="inactiveClass">Inactive</div>
						</div>
						<!-- Column: Actions -->
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/egg.svg')"
										style="margin-right: 10px" /></span
								>Available Eggs
							</div>
							<div class="success-text">{{ availableEgg }}</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span>
									<b-img
										:src="require('@/assets/images/svg/machine/available.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Tray Capacity
							</div>
							<div class="head-text">{{ trayCapacity }}</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/occupied.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Egg Capacity
							</div>
							<div class="head-text">{{ eggCapacity }}</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- setter modal -->
		<b-modal
			id="addHatcher"
			title="Add Setter Details"
			size="lg"
			ok-title="Submit"
			@ok="addSetterMachine()"
		>
			<form ref="form" @submit="addSetterMachine" method="post">
				<b-form-group label-for="i-first-name" label="Setter ID">
					<b-form-input
						id="setter_name"
						placeholder=""
						v-model="machineData.machine_id"
					/>
				</b-form-group>

				<b-form-group label-for="egg-capacity" label="Egg Capacity">
					<b-form-input
						id="egg_capacity"
						placeholder=""
						v-model="machineData.egg_capacity"
					/>
				</b-form-group>

				<b-form-group label-for="No. of tray" label="No of tray">
					<b-form-input
						id="tray_no"
						placeholder=""
						v-model="machineData.tray_capacity"
					/>
				</b-form-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		vSelect,
		BImg,
	},
	data() {
		return {
			machineData: {
				machine_id: null,
				egg_capacity: null,
				tray_capacity: null,
				machine_type: "setter",
			},
		};
	},
	props: [
		"id",
		"hatchery_device_id",
		"setterName",
		"humidity",
		"temperature",
		"availableEgg",
		"trayCapacity",
		"eggCapacity",
		"deviceId",
		"status",
	],
	methods: {
		setterView() {
			this.$router.push({
				name: `apps-machine-setter-view`,
				query: {
					machineId: this.id,
					hatchery_device_id: this.hatchery_device_id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;
	height: 100%;
	padding: 1rem 1rem;
	margin: auto;
}
.head-text {
	font-size: 18px;
	color: #1d1d1d;
}
.red-text {
	color: #f6667e;
	font-size: 10px;
}
.info-text {
	color: #1c54e4;
	font-size: 19px;
}
.success-text {
	color: rgba(41, 109, 180, 1);
	font-size: 15px;
}
.svg-img {
	width: 17px;
	height: 17px;
	// margin-right: 10px
}
.attach-device {
	background-color: #296db4;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	padding: 5px 5px;
	justify-content: center;
	border-radius: 4px;
}
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	justify-content: center;
	border-radius: 4px;
}
.inactiveClass {
	background-color: #dd2e61;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	justify-content: center;
	border-radius: 4px;
}

.dropdown-toggle::after {
	display: none !important;
}
.more-button {
	padding: 0 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
